import React from "react";
import {graphql} from 'gatsby';
import Helmet from 'react-helmet';

import Layout from "../components/layout";
import PageHeader from "../components/page-header.js";
import IntroBlock from "../components/intro-block.js" ;
import ContentBlock from "../components/content-block.js" ;
import PostList from '../components/post-list.js';

const About = ({ data }) => {

  const page = data.page ;
  const posts = data.posts.edges.slice().reverse() ;
  const mail = data.mail.acf.mail_url ;

  return (
    <Layout>
      <Helmet title={page.title}></Helmet>
      <PageHeader pageTitle={page.title} />
      <IntroBlock 
        text={page.acf.introduction} 
        mail={mail}
      />
      <ContentBlock 
        sectionTitle="La céramique, une passion"
        text={page.acf.ceramique}
        img={page.acf.image_ceramique.localFile.childImageSharp.fluid}
        alt={page.acf.image_ceramique.alt_text}
        type="normal"
      />
      <ContentBlock 
        sectionTitle="Les services"
        text={page.acf.services}
        img={page.acf.image_services.localFile.childImageSharp.fluid}
        alt={page.acf.image_services.alt_text}
        type="invert"
      />
      <PostList 
        posts={posts} 
        sectionTitle="Travaux récents"
      />
    </Layout>
  )
}

export const query  = graphql`
  query {
    page: wordpressPage (slug: {eq: "a-propos"}){
      title
      acf {
        introduction
        ceramique
        image_ceramique {
          alt_text
          localFile {
            name
            childImageSharp {
              fluid (
                maxWidth: 600, 
                maxHeight: 800, 
                cropFocus: CENTER
              ){
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        services
        image_services {
          alt_text
          localFile {
            name
            childImageSharp {
              fluid (
                maxWidth: 600, 
                maxHeight: 800, 
                cropFocus: CENTER
              ){
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    posts: allWordpressPost (
      limit: 4, 
      filter:{ categories: {elemMatch:{slug:{eq: "travaux"}}} }, 
      sort:{fields: [date]}
    ){
      edges {
        node {
          id
          title
          content
          date(formatString: "D MMMM YYYY", locale: "fr")
          excerpt
          slug
          acf {
            post_head {
              alt_text
              title
              localFile {
                name
                childImageSharp {
                  fluid (maxWidth: 1200, maxHeight: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
          tags {
            slug
	    id
          }
        }
      }
    }
    mail: wordpressPage (slug: {eq: "home"} ) {
      acf {
        mail_url
      }
    }
  }
`
 export default About ;
