import React from 'react';
import Img from 'gatsby-image' ;

const ContentBlock = ({ sectionTitle, text, img, alt, type }) => {
  return (
    <div className="content-block">
      <div className={`inner-container ${type}`}>
        <div className="image">
          <Img fluid={img} 
            alt={alt} 
            title={alt}/>
        </div>
        <div className="text">
          <div className="section">
            <h2 
              className='section-title'
              dangerouslySetInnerHTML={{__html : sectionTitle }}>
            </h2>
          </div>
          <div dangerouslySetInnerHTML={{__html : text }}></div>
        </div>
      </div>
    </div>
  )
}
 export default ContentBlock ;
